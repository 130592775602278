var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fire-alert"
  }, [_c('div', {
    staticClass: "fire-alert__content",
    class: {
      light: _vm.light
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/icons/christmas_tree_winter_icon.svg'),
      "alt": "Christmas"
    }
  }), _c('p', {
    staticClass: "fire-alert__text"
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
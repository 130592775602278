import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import AppSelect from "@/components/AppSelect";
export default {
  components: {
    AppSelect
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validate: v => ["default", "light"].includes(v)
    },
    id: {
      type: String,
      default: null
    },
    wide: {
      type: Boolean,
      default: false
    },
    grow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemsComputed() {
      return !this.items ? [] : this.items.map(i => ({
        id: i.value,
        title: i.text
      }));
    },
    uid() {
      return this._uid;
    },
    computedId() {
      return this.id || `ui-${this.uid}`;
    },
    model: {
      get() {
        return this.itemsComputed.find(item => String(item.id) === String(this.value));
      },
      set(v) {
        this.$emit("input", this.items.find(item => String(item.value) === String(v.id)).value);
      }
    }
  }
};
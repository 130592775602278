var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.type != 'addLimit' ? _c('FireAlert', {
    staticStyle: {
      "margin-bottom": "30px"
    }
  }, [_vm._v(" Новогодняя распродажа в SalesFinder! "), _c('br'), _vm._v(" Выбирайте подарок сами: скидка до 50% или 12 месяцев в подарок! "), _c('a', {
    attrs: {
      "href": "https://www.help.salesfinder.ru/ny_sale",
      "target": "_blank"
    }
  }, [_vm._v(" Узнать подробности >> ")])]) : _vm._e(), _c('div', {
    staticClass: "payment-form"
  }, [_c('UiForm', [_c('UiFormGrid', [_vm.type == 'addLimit' ? _c('div', [_c('UiFormLabel', {
    attrs: {
      "for": _vm.getId('plan')
    }
  }, [_vm._v(" Выберите пакет дополнительных "), _c('br'), _vm._v("лимитов репрайсера ")])], 1) : _vm.type !== 'prolong' ? _c('div', [_c('UiFormLabel', {
    attrs: {
      "for": _vm.getId('plan')
    }
  }, [_vm._v(" Выберите тариф ")])], 1) : _vm._e(), _vm.type !== 'prolong' ? _c('div', [_c('UiFormSelect', {
    attrs: {
      "items": _vm.plansOptions,
      "id": _vm.getId('plan'),
      "wide": ""
    },
    model: {
      value: _vm.plan,
      callback: function ($$v) {
        _vm.plan = $$v;
      },
      expression: "plan"
    }
  })], 1) : _vm._e(), _vm.type != 'addLimit' ? _c('div', [_c('UiFormLabel', {
    attrs: {
      "for": _vm.getId('period')
    }
  }, [_vm._v("Срок подписки")])], 1) : _vm._e(), _vm.type != 'addLimit' ? _c('div', [_c('UiFormSelect', {
    attrs: {
      "items": _vm.periodsOptions,
      "id": _vm.getId('period'),
      "wide": ""
    },
    model: {
      value: _vm.period,
      callback: function ($$v) {
        _vm.period = $$v;
      },
      expression: "period"
    }
  })], 1) : _vm._e(), _vm.type == 'addLimit' ? [_vm.addLimitButtonFeedBack ? _c('div', {
    staticClass: "full"
  }, [_c('div', {
    staticClass: "alert",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" Заказ оформлен! ")]), _c('div', {
    staticClass: "msg",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" Менеджер свяжется с Вами в ближайшее время для подтверждения и оплаты."), _c('br'), _vm._v(" Номер Вашего обращения: #" + _vm._s(_vm.addLimitButtonFeedBack.id) + " ")])]) : _c('div', {
    staticClass: "full"
  }, [_c('UiButtonsGroup', [_c('UiButton', {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "variant": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickAddLimit.apply(null, arguments);
      }
    }
  }, [_vm._v(" Оформить заказ ")])], 1)], 1)] : _vm._e(), _vm.lastCalculation && _vm.type != 'addLimit' ? [_vm.lastCalculation && _vm.lastCalculation.total === 0 ? [_c('div', {
    staticClass: "full"
  }, [_vm._v("Доплата не требуется")])] : [_c('div', {
    staticClass: "full"
  }, [_c('UiFormVariantsInput', {
    attrs: {
      "items": _vm.paymentMethods,
      "id": _vm.getId('paymentMethod'),
      "label": "Метод оплаты"
    },
    model: {
      value: _vm.paymentMethod,
      callback: function ($$v) {
        _vm.paymentMethod = $$v;
      },
      expression: "paymentMethod"
    }
  })], 1), _c('div', {
    staticClass: "full",
    class: {
      hidden: !_vm.lastCalculation
    }
  }, [_vm._v(" Сумма к оплате: "), _vm.lastCalculation ? _c('strong', [_vm._v(_vm._s(_vm.formatMoney(_vm.lastCalculation.total)) + " ")]) : _vm._e(), _vm.lastCalculation && _vm.lastCalculation.total !== _vm.lastCalculation.baseTotal ? _c('strong', {
    staticClass: "mr-2"
  }, [_c('s', [_vm._v(_vm._s(_vm.formatMoney(_vm.lastCalculation.baseTotal)))])]) : _vm._e()])], _vm.promocodeAllowed && _vm.lastCalculation && _vm.lastCalculation.total ? _c('div', {
    staticClass: "full"
  }, [_c('div', {
    staticClass: "promocode-opener",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.togglePromocodeInput.apply(null, arguments);
      }
    }
  }, [_vm._v(" Есть промокод? "), _c('svg', {
    staticClass: "promocode-opener__arrow",
    attrs: {
      "width": "8",
      "height": "8",
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.293 0.439453L5.99997 4.73245L1.70697 0.439453L0.292969 1.85345L5.99997 7.56045L11.707 1.85345L10.293 0.439453Z",
      "fill": "black"
    }
  })])]), _vm.showPromocode ? _c('div', {
    staticClass: "promocode"
  }, [_c('div', {
    staticClass: "promocode__input-wrapper"
  }, [_c('UiFormInput', {
    ref: "promocodeInput",
    staticClass: "promocode__input",
    attrs: {
      "placeholder": "Промокод"
    },
    model: {
      value: _vm.promocodeComputed,
      callback: function ($$v) {
        _vm.promocodeComputed = $$v;
      },
      expression: "promocodeComputed"
    }
  }), _vm.loading ? _c('spinner', {
    staticClass: "promocode__spinner",
    attrs: {
      "dark": true
    }
  }) : _vm._e()], 1), _vm.promocodeResult ? _c('div', {
    staticClass: "promocode__result"
  }, [_vm.promocodeResult.type === 'error' ? _c('span', {
    staticClass: "red"
  }, [_vm._v(" " + _vm._s(_vm.promocodeResult.text) + " ")]) : _vm._e(), _vm.promocodeResult.type === 'success' ? _c('span', {
    staticClass: "green"
  }, [_vm._v(" " + _vm._s(_vm.promocodeResult.text) + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "full"
  }, [_c('UiButtonsGroup', [_c('UiButton', {
    attrs: {
      "variant": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm.lastCalculation && _vm.lastCalculation.total === 0 ? [_vm._v(" Сменить тариф ")] : _vm.paymentMethod === 'bank_invoice' ? [_vm._v("Выставить счет ")] : [_vm._v("Перейти к оплате ")]], 2), _vm.skipButton ? _c('UiButton', {
    attrs: {
      "variant": "white"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('skip');
      }
    }
  }, [_vm._v("Пропустить и оплатить позже")]) : _vm._e()], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ui-input-group",
    class: [_vm.variant, {
      grow: _vm.grow,
      wide: _vm.wide
    }]
  }, [_vm.label ? _c('UiFormLabel', {
    staticClass: "label",
    attrs: {
      "for": _vm.computedId,
      "variant": _vm.variant
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('AppSelect', {
    attrs: {
      "items": _vm.itemsComputed,
      "width": "300"
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };